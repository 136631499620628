import { memo } from 'react';

export type SchemaType = {
  article?: Article;
  breadcrumb?: Breadcrumb;
  product?: Product;
  podcast?: Podcast;
  website?: Website;
  organization?: NewsMediaOrganization;
  video?: Video;
  collectionpage?: CollectionPage;
};

export interface InternalSchemaI {
  '@context'?: string;
  '@type': string;
}

export interface Article extends InternalSchemaI {
  '@type': 'Article' | 'NewsArticle';
  headline: string;
  alternativeHeadline: string;
  image: string[];
  author: Author;
  publisher?: Publisher;
  url: string;
  mainEntityOfPage: MainEntityOfPage;
  datePublished?: string;
  dateModified?: string;
}

export interface Breadcrumb extends InternalSchemaI {
  '@type': 'BreadcrumbList';
  '@id': string;
  itemListElement: {
    '@type': string;
    position: number;
    name: string;
    item: string;
  }[];
}

export interface CollectionPage extends InternalSchemaI {
  '@type': 'CollectionPage';
  '@id': string;
  url: string;
  name: string;
  isPartOf: {
    '@id': string;
    '@type': string;
    potentialAction: {
      '@type': string;
      target: string;
    };
  };
  primaryImageOfPage: {
    '@type': string;
    inLanguage: string;
    '@id': string;
    url: string;
    contentUrl: string;
    width: number;
    height: number;
    caption: string;
  };
  image: {
    '@id': string;
  };
  thumbnailUrl: string;
  datePublished: string;
  dateModified: string;
  description: string;
  breadcrumb: {
    '@id': string;
  };
  inLanguage: string;
  potentialAction: {
    '@type': string;
    target: string[];
  }[];
  publisher: {
    '@id': string;
  };
}

export interface Author {
  '@type': string;
  name: string;
  url?: string;
}

export interface Publisher {
  '@type': string;
  '@id': string;
  name: string;
  logo: Logo;
}

export interface Logo {
  '@type': string;
  url: string;
}

export interface MainEntityOfPage {
  '@type': string;
  '@id': string;
}

export interface AggregateRating {
  '@type': string;
  ratingValue: number;
  reviewCount: number;
  bestRating: number;
  worstRating: number;
}

export interface Offer {
  '@type': string;
  availability: string;
  price: number;
  priceCurrency: string;
  url: string;
}

export interface Review {
  '@type': string;
  author: Author;
  reviewBody: string;
  name: string;
  reviewRating: ReviewRating;
}

export interface ReviewRating {
  '@type': 'Rating';
  bestRating: number;
  ratingValue: number;
  worstRating: number;
}

export interface Product extends InternalSchemaI {
  '@type': 'Product';
  aggregateRating: AggregateRating;
  name: string;
  image: string;
  offers: Offer[];
  review: Review;
  description: string;
  brand: string;
  sku: number;
}

export interface AssociatedMedia {
  '@type': string;
  contentUrl: string;
}

export interface PartOfSeries {
  '@type': string;
  name: string;
  url: string;
}

export interface ProductionCompany {
  '@type': string;
  name: string;
}

export interface Podcast extends InternalSchemaI {
  '@type': 'PodcastEpisode';
  url: string;
  name: string;
  datePublished: string;
  timeRequired: string;
  description: string;
  associatedMedia: AssociatedMedia;
  partOfSeries: PartOfSeries;
  productionCompany: ProductionCompany;
}

export interface Website extends InternalSchemaI {
  '@type': 'WebSite';
  name: string;
  url: string;
  description: string;
  alternateName: string;
  inLanguage: string;
  potentialAction: {
    '@type': string;
    target: string;
    'query-input': string;
  };
}

export interface NewsMediaOrganization extends InternalSchemaI {
  '@type': 'NewsMediaOrganization';
  url: string;
  logo: {
    '@type': string;
    url: string;
    width: string;
    height: string;
  };
  name: string;
  sameAs: string[];
  alternateName: string;
  address: {
    '@type': 'PostalAddress';
    '@id': string;
    streetAddress: string;
    addressLocality: string;
    postalCode: string;
    addressRegion: string;
    addressCountry: string;
  };
}

export interface Video extends InternalSchemaI {
  '@type': 'VideoObject';
  name: string;
  description: string;
  thumbnailUrl: string;
  uploadDate: string;
  duration: string;
  embedUrl: string;
  dataPublished: string;
}

type InternalSchemaProps = {
  schema: Article | Breadcrumb | Product | Podcast | Video | Website | NewsMediaOrganization | CollectionPage;
};

export function InternalSchema({ schema }: InternalSchemaProps) {
  const schemaUse = schema;

  switch (schemaUse['@type']) {
    case 'BreadcrumbList':
      schemaUse['@id'] = 'https://canaltech.com.br/#breadcrumb';
      break;
    case 'WebSite':
      schemaUse['@id'] = 'https://canaltech.com.br/#website';
      schemaUse.name = 'Canaltech';
      schemaUse.url = 'https://canaltech.com.br';
      schemaUse.description = 'Tecnologia pra quem entende';
      schemaUse.alternateName = 'CT';
      schemaUse.inLanguage = 'pt-BR';
      schemaUse.potentialAction = {
        '@type': 'SearchAction',
        target: 'https://canaltech.com.br/busca/?q={search_term_string}',
        'query-input': 'required name=search_term_string',
      };
      break;
    case 'NewsMediaOrganization':
      schemaUse['@id'] = 'https://canaltech.com.br/#organization';
      schemaUse.url = 'https://canaltech.com.br';
      schemaUse.logo = {
        '@type': 'ImageObject',
        url: 'https://img.canaltech.com.br/canaltech-512.png',
        width: '512',
        height: '512',
      };
      schemaUse.name = 'Canaltech';
      schemaUse.sameAs = [
        'https://facebook.com/canaltech',
        'https://twitter.com/canaltech',
        'https://www.tiktok.com/canaltech',
        'https://www.youtube.com/canaltech',
        'https://www.youtube.com/c/MaravilhasdaTecnologia',
        'https://pt.wikipedia.org/wiki/Canaltech',
        'https://www.linkedin.com/company/canaltech-brasil',
        'https://www.instagram.com/canaltech',
        'https://flipboard.com/@canaltech',
        'https://t.me/Canaltech_Oficial',
        'https://t.me/ctofertas',
      ];
      schemaUse.alternateName = 'CT';
      schemaUse.address = {
        '@id': 'https://canaltech.com.br/#local-main-place-address',
        '@type': 'PostalAddress',
        streetAddress: 'Rua Maria Prestes Maia, 300',
        addressLocality: 'São Paulo',
        postalCode: '02047000',
        addressRegion: 'SP',
        addressCountry: 'BR',
      };
      break;
    case 'Article':
      if (schemaUse?.datePublished) {
        schemaUse['@type'] = 'NewsArticle';
      }
      schemaUse.publisher['@id'] = 'https://canaltech.com.br/#organization';
      break;
    case 'CollectionPage':
      schemaUse['@id'] = 'https://canaltech.com.br/#webpage';
      (schemaUse.isPartOf['@id'] = 'https://canaltech.com.br/#website'), (schemaUse.primaryImageOfPage['@id'] = 'https://canaltech.com.br/#primaryimage');
      schemaUse.image['@id'] = 'https://canaltech.com.br/#primaryimage';
      schemaUse.breadcrumb['@id'] = 'https://canaltech.com.br/#breadcrumb';
    default:
      break;
  }

  if (!schemaUse['@context']) {
    schemaUse['@context'] = 'http://schema.org';
  }

  if ('author' in schemaUse && !schemaUse.publisher) {
    schemaUse.publisher = {
      '@type': 'Organization',
      '@id': 'https://canaltech.com.br/#organization',
      name: 'Canaltech',
      logo: {
        '@type': 'ImageObject',
        url: 'https://img.canaltech.com.br/logo-ct.png',
      },
    };
  }

  // eslint-disable-next-line react/no-danger
  return <script type="application/ld+json" className={typeof schema} dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaUse) }} />;
}

export const ApplySchema = ({ schema }: { schema: SchemaType }) => (
  <>
    {Object.values(schema).map((schemaItem, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <InternalSchemaMemo key={index} schema={schemaItem} />
    ))}
  </>
);

const InternalSchemaMemo = memo(InternalSchema, () => true);

export default InternalSchemaMemo;
